














































































































import '@/declarations/vue-meta';
import '@/declarations/config';
// import { mapGetters } from 'vuex';
import Config, { test, QuestionFields } from '@/configReader/config';
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import modal from '@/components/Modal.vue';

const config = new Config();

@Component({
  components: {
    modal,
  },
})
export default class Form extends Vue {
  async created(): Promise<void> {
    console.log(this.$route.query);

    // if (localStorage[`${route}_test`] === this.$route.params.id) {
    //   config.setConfig = this.$config;
    //   this.testIndex = config.matchRoute(localStorage.test);
    //   this.testData = config.extractData(this.testIndex);
    // } else {
    config.setConfig = this.$config;
    this.testIndex = config.matchRoute(this.$route.params.id);
    this.testData = config.extractData(this.testIndex);
    // }
    try {
      await this.fetchDatenschutz();
    } catch (e) {
      console.error(e);
    }
  }

  // TODO: Remove hardcoding
  // mounted(): void {
  //   const route = this.$route.params.id;
  //   if (localStorage[`${route}_test`] === this.$route.params.id) {
  //     if (localStorage[`${route}_formData`]) {
  //       console.log(localStorage.getItem(`${route}_id`));
  //       this.formData = JSON.parse(localStorage[`${route}_formData`]);
  //       if (Number(localStorage.getItem(`${route}_id`))) {
  //         this.activeStep = Number(localStorage.getItem(`${route}_id`));
  //         console.log(this.activeStep);
  //       }
  //     }
  //   }
  // }

  get virtualPage(): string {
    return `${window.location.pathname}/step${this.activeStep + 1}`;
  }

  activeStep = 0;

  showValue = false;

  loading = false;

  animation = 'slide-in';

  formData: any = {};

  testIndex = 0;

  testData: test = config.extractData(this.testIndex);

  buttonWeight: Array<number> = [];

  isPrivacyPolicy = false;

  privacyPolicy = '';

  get Places(): number {
    return this.freePlaces(this.minutesFromMidnight());
  }

  addValueToData(data: string | number, key: string, buttonWeight?: number): void {
    this.formData[key] = data;
    if (buttonWeight) {
      this.buttonWeight.push(buttonWeight);
    }
  }

  goBack(): void {
    this.animation = 'slide-out-back';
    setTimeout(() => {
      this.animation = 'slide-in-back';
      if (this.activeStep !== 0) {
        this.activeStep -= 1;
      }
    }, 300);
  }

  // eslint-disable-next-line class-methods-use-this
  extractVariable(url: string, variable: string): string {
    return url.replace(`{points}`, variable);
  }

  submit(): void {
    // const weight = this.buttonWeight.reduce((a, b) => a + b, 0);
    if (this.checkFields()) {
      this.sendData();
      // if (localStorage[`${route}_test`] === this.$route.params.id) {
      //   localStorage.removeItem(`${route}_formData`);
      //   localStorage.removeItem(`${route}_test`);
      //   localStorage.removeItem(`${route}_id`);
      // }
      if (this.testData.thanks.body.redirect.active) {
        console.log(
          this.extractVariable(
            this.testData.thanks.body.redirect.toPath,
            this.formData.buttonWeight
          )
        );
        window.location.href = this.extractVariable(
          this.testData.thanks.body.redirect.toPath,
          this.formData.buttonWeight
        );
      } else {
        // eslint-disable-next-line no-undef
        this.$router.push(`${this.$route.params.id}/thanks`);
      }
      // window.location.href = `https://localapplicator-be.myshopify.com/?rslt=${weight}`;
    }
  }

  sendData(): void {
    this.formData.buttonWeight = this.buttonWeight.reduce((a, b) => a + b, 0);

    // localStorage.setItem(`${route}_id`, String(this.testData.questions.length - 2));
    // localStorage.setItem(`${route}_test`, String(this.$route.params.id));
    // localStorage.setItem(`${route}_formData`, JSON.stringify(this.formData));

    this.formData.utm_campaign = this.$route.query.utm_campaign || '';
    this.formData.utm_content = this.$route.query.utm_content || '';
    this.formData.utm_medium = this.$route.query.utm_medium || '';
    this.formData.utm_lead_source = this.$route.query.utm_source || '';
    this.formData.utm_term = this.$route.query.utm_term || '';
    this.formData.gclid_field = this.$route.query.gclid || '';

    const sendObject = this.testData.questions[this.activeStep].send;
    if (sendObject.active && sendObject.apiUrl) {
      axios.post(sendObject.apiUrl, this.formData).catch((err) => {
        console.error(err);
      });
    } else {
      axios.post(this.testData.apiUrl, this.formData).catch((err) => {
        console.error(err);
      });
    }
  }

  nextStep(buttonValue: string | number, weight?: number): void {
    this.$gtag.pageview({ page_path: this.virtualPage });
    // const w: number = window.innerWidth;
    // if (w < 961) {
    //   window.scrollTo(0, 100);
    // }

    this.animation = 'slide-out';
    setTimeout(() => {
      this.animation = 'slide-in';
      // this.$forceUpdate();
      if (this.testData.questions[this.activeStep].type === 'input') {
        if (this.checkFields()) {
          if (this.testData.questions[this.activeStep].send) {
            this.sendData();
          }
          this.activeStep += 1;
        }
      } else {
        this.addValueToData(buttonValue, this.testData.questions[this.activeStep].key, weight);
        this.activeStep += 1;
      }

      if (
        this.testData.questions[this.activeStep] !== undefined &&
        this.testData.questions[this.activeStep].type === 'loading'
      ) {
        this.loading = true;
        console.log(this.loading);
        // this.activeStep += 1;
        setTimeout(() => {
          this.loading = false;
          if (this.testData.questions[this.activeStep].redirect) {
            window.location.href = this.extractVariable(
              `${this.testData.questions[this.activeStep].redirect}`,
              this.formData.buttonWeight
            );
          } else {
            this.activeStep += 1;
          }
        }, 5000);
      }
    }, 300);
  }

  checkFields(): boolean {
    let valid = false;
    this.testData.questions[this.activeStep].fields.forEach((field: QuestionFields): void => {
      if (field.value === '') {
        // eslint-disable-next-line no-param-reassign
        field.valid = false;
        valid = false;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.addValueToData(field.value, field.key!);
        // eslint-disable-next-line no-param-reassign
        field.valid = true;
        valid = true;
      }
    });
    return valid;
  }

  // eslint-disable-next-line class-methods-use-this
  minutesFromMidnight(): number {
    const midnight: Date = new Date();
    midnight.setHours(0);
    midnight.setMinutes(0);
    midnight.setSeconds(0);
    midnight.setMilliseconds(0);
    return Math.round((new Date().getTime() - midnight.getTime()) / 1000 / 60);
  }

  // eslint-disable-next-line class-methods-use-this
  freePlaces(minutes: number): number {
    const top = 65;
    const step = 15;
    const block = Math.round(minutes / step);

    if (block + 1 > top) {
      return top - (block * Math.round(top / block) - top);
    }
    return top - block;
  }

  async fetchDatenschutz(): Promise<void> {
    const fetcher = (url: string) => fetch(url).then((r) => r.text());
    const data = await fetcher('html/privacyPolicy.html');
    this.privacyPolicy = data;
  }
}
