






































































// @ is an alias to /src
import './style/main.scss';
import './style/responsive.scss';
import Form from '@/components/Form/Form.vue';
import testfooter from '@/components/Footer.vue';
import { Component, Vue } from 'vue-property-decorator';
import Config, { test } from '@/configReader/config';
import { setOptions, bootstrap } from 'vue-gtag';

const config = new Config();

@Component({
  components: {
    Form,
    testfooter,
  },
  metaInfo() {
    return {
      title: this.$store.getters.GET_CONFIG.seoTitle,
    };
  },
})
export default class Home extends Vue {
  get Sconfig(): test {
    return this.$store.getters.GET_CONFIG;
  }

  created(): void {
    config.setConfig = this.$config;
    const testIndex = config.matchRoute(this.$route.params.id);
    config.extractData(testIndex);

    if (this.Sconfig.css) {
      this.addCustomCSS(this.Sconfig.css);
    }

    setOptions({
      config: { id: this.Sconfig.gtag },
    });

    bootstrap();
  }

  // eslint-disable-next-line class-methods-use-this
  addCustomCSS(css: string): void {
    if (css) {
      const head = document.querySelector('head');
      const link = document.createElement('link');

      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = css;

      // eslint-disable-next-line no-unused-expressions
      head?.appendChild(link);
    }
  }
}
