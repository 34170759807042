import Vue from 'vue';
import VueMeta from 'vue-meta'
import fetch, { Response } from 'node-fetch'
import VueGtag from "vue-gtag";
import { test } from '@/configReader/config'
import App from './App.vue';
import router from './router'
import store from './store'

Vue.use(VueGtag, {bootstrap: false});


Vue.use(VueMeta)
Vue.config.productionTip = false;


fetch(`config.json`)
  .then((response: Response) => {
    response.json().then((configData: Array<test>) => {
       Vue.prototype.$config = configData
       new Vue({
         router,
         store,
         render: (h) => h(App)
       }).$mount('#app');
    })
})

