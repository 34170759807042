


















import fetch from 'node-fetch';
import modal from '@/components/Modal.vue';
import { Component, Vue } from 'vue-property-decorator';
import { test } from '@/configReader/config';

@Component({
  components: {
    modal,
  },
})
export default class Footer extends Vue {
  async created(): Promise<void> {
    try {
      await this.fetchPrivacyPolicy();
      await this.fetchImprint();
    } catch (e) {
      console.error(e);
    }
  }

  mounted(): void {
    console.log(this.Sconfig.footer);
  }

  get Sconfig(): test {
    return this.$store.getters.GET_CONFIG;
  }

  imprint = '';

  pricayPolicy = '';

  isImprint = false;

  isPrivacyPolicy = false;

  async fetchImprint(): Promise<void> {
    const fetcher = (url: string) => fetch(url).then((r) => r.text());
    const data = await fetcher(`html/imprint.html`);
    this.imprint = data;
  }

  async fetchPrivacyPolicy(): Promise<void> {
    const fetcher = (url: string) => fetch(url).then((r) => r.text());
    const data = await fetcher(`html/privacyPolicy.html`);
    this.pricayPolicy = data;
  }
}
