import store from '@/store/index';

export interface QuestionFields {
    label: string; 
    value: string;
    valid: boolean;
    weight: number;
    key?: string;
}

interface SendObject {
    active: boolean;
    apiUrl?: string;
}

interface Image {
    active: boolean;
    name: string;
}

interface Questions {
    title: string;
    type: string;
    key: string;
    image?: Image;
    send: SendObject;
    fields: Array<QuestionFields>;
    redirect?: string;
}

interface TextBlock {
    heading: string;
    subheading: string;
    text: string;
}

interface ListBlock {
    heading: string;
    text: Array<string>;
}

interface Redirect {
    active: boolean;
    toPath: string
}
interface ThanksBody {
    seoTitle: string;
    firstBlock: TextBlock;
    secondBlock: TextBlock;
    redirect: Redirect;
}

interface Body {
    body: ThanksBody
}

interface Footer {
    imprint: string;
    privacyPolicy: string;
}

export interface test {
    url: Array<string>;
    imgDir: string;
    gtag: string;
    css?: string;
    header: string;
    subheader: string;
    apiUrl: string;
    ytSrc: string;
    seoTitle: string;
    expectation: ListBlock;
    participation: ListBlock;
    advantages: ListBlock;
    questions: Array<Questions>;
    thanks: Body;
    footer: Footer
}        

export default class Config  {
    protected configR: Array<test> = [];
    
    public set setConfig(config: Array<test>) {
    this.configR = config;
    }

    /**
     * extracts the test data from the index
     * @returns current test data of the url
     * 
     * @param index - the index of the test data inside the config.json
     * 
     * @example
     * ```
     * // returns the data for index 0
     * extractData(matchRoute("test"));
     * ```
     */
    public extractData(index: number): test {
        store.commit('SET_CONFIG', this.configR[Math.abs(index)]);
        return this.configR[Math.abs(index)];
    }

    /**
     * matches the route with the test data index
     * @returns the correct index of the test data if the url is included in any of these tests.
     * 
     * @param urlString - The url parameter of the test
     */ 
    // eslint-disable-next-line consistent-return
    public matchRoute(urlString: string): number {
        for(let i = 0; i <= (this.configR.length); i += 1) {
            if(this.configR[i] !== undefined) {
                const { url } = this.configR[i];
                if(url.includes(urlString)) {
                    return i;
                }
            }
        }
        return 0
    }
}