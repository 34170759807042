<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-footer">
            &nbsp;
            <div class="modal-default-button" @click="$emit('close')">
              <span class="material-icons">close</span>
            </div>
          </div>
          <div class="modal-body">
            <slot name="body"> default body </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Vue } from 'vue-property-decorator';

export default class Modal extends Vue {}
</script>

<style>
.modal-footer {
  display: flex;
  justify-content: flex-end;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  height: 85%;
  overflow-y: scroll;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  padding: 5px 10px;
  font-weight: bold;
  border-radius: 5px;
  letter-spacing: 0px;
  font-size: 16px;
  display: inline-block;
  width: auto;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: #3d92fb;
  color: white;
}

.modal-default-button {
  font-size: 8px !important;
  background: #fff;
  padding: 5px;
  border-radius: 99px;
  border: 2px solid grey;
  cursor: pointer;
  position: fixed;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (max-width: 960px) {
  .modal-container {
    width: 90%;
    max-width: 360px;
    overflow-x: hidden;
  }
}
</style>
