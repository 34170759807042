import Vue from 'vue'
import Vuex from 'vuex'
// import { test } from '@/configReader/config';
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    config: {},
  },
  getters: {
    GET_CONFIG(state) {
      return state.config;
    }
  },
  mutations: {
    SET_CONFIG(state, value) {
      state.config = value
    }
  },
  actions: {
  },
  modules: {
  }
})